import React, { useRef } from "react";
import {
  Container,
  Text,
  // Audio
} from "@atoms";
import { HomeAudioLinks } from "@molecules";
import classNames from "classnames";

const HeroHome = ({
  label,
  heading,
  svg,
  transcriptText,
  transcriptSubtext,
  audio,
  audioTitle,
  transcript,
}) => {
  const inside = useRef(null);

  return (
    <div ref={inside} className="relative z-40 min-h-[99vh] pt-24 lg:pt-32">
      <Container>
        {label && (
          <Text variant="label" className="mb-8 text-black">
            {label}
          </Text>
        )}
        <div className="max-w-3xl">
          <Text
            tag="h1"
            className={classNames(
              "text-[3rem] font-bold uppercase leading-none text-viridian sm:text-[4rem] md:text-[5rem] lg:text-[6rem]",
              { "sr-only": svg, "break-all sm:break-normal": !svg }
            )}
          >
            {heading}
          </Text>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: svg }}
          />
        </div>
        <div className="mt-14 flex items-center gap-4">
          <HomeAudioLinks
            heading={transcriptText}
            subHeading={transcriptSubtext}
            transcript={transcript}
            audio={audio}
            title={audioTitle}
          />
        </div>
      </Container>
    </div>
  );
};

HeroHome.defaultProps = {};

export default HeroHome;
